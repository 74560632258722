<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-cell title="姓名" :value="user.name" />
            <van-cell title="手机号" :value="user.phone" />
            <van-cell title="身份证号" :value="user.icard" />
            <van-cell title="身份证正面">
                <template #label>
                    <uploader-component
                        :farr="user.icard_file_a"
                        :show_upload="false"
                        :deletable="false"
                    ></uploader-component>
                </template>
            </van-cell>
            <van-cell title="身份证反面">
                <template #label>
                    <uploader-component
                        :farr="user.icard_file_b"
                        :show_upload="false"
                        :deletable="false"
                    ></uploader-component>
                </template>
            </van-cell>
            <van-cell title="驾驶证正面">
                <template #label>
                    <uploader-component
                        :farr="user.jscard_file_a"
                        :show_upload="false"
                        :deletable="false"
                    ></uploader-component>
                </template>
            </van-cell>
            <van-cell title="驾驶证反面">
                <template #label>
                    <uploader-component
                        :farr="user.jscard_file_b"
                        :show_upload="false"
                        :deletable="false"
                    ></uploader-component>
                </template>
            </van-cell>
        </van-cell-group>
    </div>

</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { driver_information_request } from '@/network/user'

export default {
    name:'UserInformationComponent',
    data(){
        return {
            user: {
                icard_file_a: [],
                icard_file_b: [],
                jscard_file_a: [],
                jscard_file_b: []
            },
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '个人信息',

        }
    },
    computed:{},
    methods:{
        get_my_information() {
            this.$store.commit('true_loading')
            driver_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.user = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_my_information()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        NavBarComponent,
        LoadingComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.information_portrait {
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}
.information_portrait>img {
    width: 3rem;
    height: 3rem;
    border-radius:50%;
    object-fit: unset;
}
</style>